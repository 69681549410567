
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

 window.Vue = require('vue');

 import'./bulma';
 import'./script';

 /*---------------Imports-------------*/
 import VueSweetalert2 from 'vue-sweetalert2';
 import Alert from './components/Alert';

 /*---------------uses-------------*/
 Vue.component('alert', Alert);
 Vue.use(VueSweetalert2);

 const app = new Vue({
 	el: '#app',
 });

